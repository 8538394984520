import React, { useEffect, useState } from "react";
import "./Acalvio.css";
import useFetchSummary from "../../hooks/useFetchSummary";
import useWindowWidth from "../../hooks/useWindowWidth";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import SearchBar from "../../components/SearchBar/SearchBar";
import Button from "../../components/Buttons/Button";
import ShowMoreText from "../../components/ShowMoreText/ShowMoreText";
import HorizontalLoader from "../../components/Loaders/HorizontalLoader/HorizontalLoader";
import Spinner from "../../components/Loaders/Spinner/Spinner";
import RevvanaTile from "../../components/Tiles/Revvana/RevvanaTile";
import { useLocation } from "react-router-dom";
import SuggestedQuestions from "../../components/SuggestedQuestions/SuggestedQuestions";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import useDocumentTitle from "../../hooks/useDocumentTitle.tsx";
import { useDispatch } from "react-redux";
import {
  activateSearch,
  deactivateSearch,
} from "../../redux/actions/searchActions";

interface ResponseItem {
  title: string;
  description: string;
  link: string;
  image: string;
}

interface MarketingMessage {
  link: string;
  message: string;
}

interface Data {
  responses: ResponseItem[];
  marketing_message: MarketingMessage;
}

interface ErrorType {
  message: string | null;
  status: number | null;
}


const Acalvio = () => {
 const [text, setText] = useState("");
 const [data, setData] = useState<Data>({
   responses: [],
   marketing_message: { link: "", message: "" },
 });
 const location = useLocation();
 const [tone, setTone] = useState<string>("");
 const [generationType, setGenerationType] = useState<string>("");
 const [model, setModel] = useState<string>("");
 const [summary, setSummary] = useState<string>("");
 const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
 const { fetchSummary } = useFetchSummary(setSummary, setLoadingSummary);
 const [loading, setLoading] = useState<boolean>(false);
 const [error, setError] = useState<ErrorType>({
   message: null,
   status: null,
 });
 const width = useWindowWidth();
 const { token } = useSelector((state: RootState) => state.user);
 const dispatch = useDispatch();
 useDocumentTitle("Acalvio");

 const fetchData = async ({ text }: { text: string }) => {
   const requestID = uuidv4();
   try {
     (window as any).dataLayer?.push({ requestId: requestID });
     dispatch(deactivateSearch());
     setLoading(true);
     const queryParams: { [key: string]: string } = {
       query: text,
       company: "acalvio",
       requestId: requestID,
     };
     if (tone !== "") {
       queryParams["tone"] = tone;
     }
     if (generationType !== "") {
       queryParams["generation_type"] = generationType;
     }
     if (model !== "") {
       queryParams["model"] = model;
     }
     if (token) {
       queryParams["token"] = token;
     }
     const res = await axios.post<Data>(
       `${process.env.REACT_APP_BACKEND_URL}/post_query`,
       queryParams
     );
     setData(res.data);
     fetchSummary(queryParams);
   } catch (e) {
     if (axios.isAxiosError(e)) {
       setError({
         status: e.status || null,
         message: e.message || null,
       });
     } else {
       setError({ message: "An unexpected error occurred", status: 500 });
     }
     setLoading(false);
     dispatch(activateSearch());
     alert(error?.message);
   } finally {
     setLoading(false);
   }
 };

 const handleSuggestionClick = (text: string) => {
   setText(text);
   fetchData({ text });
 };

 useEffect(() => {
   const searchParams = new URLSearchParams(location.search);
   const toneParameter = searchParams.get("tone");
   const generationTypeParameter = searchParams.get("generation_type");
   const modelParameter = searchParams.get("model");

   if (toneParameter) {
     setTone(toneParameter);
     // Do something with the parameter value
   }
   if (generationTypeParameter) {
     setGenerationType(generationTypeParameter);
     // Do something with the parameter value
   }
   if (modelParameter) {
     setModel(modelParameter);
     // Do something with the parameter value
   }
 }, [location.search]);

 return (
   <div>
     <div className="top-container revvana">
       <SearchBar
         fetchData={fetchData}
         type="revvana"
         text={text}
         setText={setText}
       />
       <div className="suggested-question-container">
         <SuggestedQuestions
           handleSuggestionClick={handleSuggestionClick}
           question="Cyber threats for finserv."
         />
         <SuggestedQuestions
           handleSuggestionClick={handleSuggestionClick}
           question="Can you help a manufacturing company?"
         />
         <SuggestedQuestions
           handleSuggestionClick={handleSuggestionClick}
           question="Worried about trojans."
         />
         <SuggestedQuestions
           handleSuggestionClick={handleSuggestionClick}
           question="Can we use it in healthcare?"
         />
         <SuggestedQuestions
           handleSuggestionClick={handleSuggestionClick}
           question="How do you compare with attivo networks?"
         />
         <SuggestedQuestions
           handleSuggestionClick={handleSuggestionClick}
           question="How are you ranked by analysts?"
         />
         <SuggestedQuestions
           handleSuggestionClick={handleSuggestionClick}
           question="Do I need Crowdstrike for honeytokens?"
         />
       </div>
       <div className="result-desc revvana">
         {loadingSummary || loading ? (
           <HorizontalLoader type="revvana" />
         ) : width < 765 ? (
           <ShowMoreText text={summary} maxLength={150} type={generationType} />
         ) : generationType == "bullet_point" ? (
           <ul className="bullet-point-summary">
             {summary.split("###").map((item) => {
               let temp = item.trim();
               if (temp.length > 0) {
                 return <li>{item}</li>;
               }
             })}
           </ul>
         ) : (
           <p>{summary}</p>
         )}
       </div>
       {loading == false &&
         loadingSummary == false &&
         data.responses.length > 0 && (
           <div className="buttons-container">
             <Button
               text="SCHEDULE A DEMO"
               type="revvana"
               link="https://www.acalvio.com/schedule-a-demo/"
             />
           </div>
         )}
     </div>
     <div className="revvana-cards-container">
       {loading == true ? (
         <div className="spinner-container">
           <Spinner type="revvana" />
         </div>
       ) : (
         <div className="revvana-tiles-container">
           {data.responses.map((item, i) => (
             <RevvanaTile
               key={i}
               title={item.title}
               desc={item.description}
               imgUrl={item.image}
               link={item.link}
             />
           ))}
         </div>
       )}
     </div>
   </div>
 );
};

export default Acalvio;
