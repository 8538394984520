import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RiskifiedMetrics.css';
import WeblessNavbar from '../../components/WeblessNavbar/WeblessNavbar';
import WeblessFooter from '../../components/WeblessFooter/WeblessFooter';
import setFavicon from '../../utils/setFavicon';
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const DASHBOARD_ID = 'aa81d376-7822-4a30-a68b-6361d6b2baed';
const LAMDA_URL = 'https://seo4q7mc6sxihppl6o5fqzcmhe0rslus.lambda-url.us-west-1.on.aws';

const RiskifiedMetrics: React.FC = () => {
  const [embedUrl, setEmbedUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { token } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    setFavicon('/assets/images/webless_favicon.png');
  }, []);

  useEffect(() => {
    const fetchEmbedUrl = async () => {
      try {
        const response = await axios.get(
          `${LAMDA_URL}?dashboard_id=${DASHBOARD_ID}&token=${token}`,
          {headers: {
            'Content-Type': 'application/json'
          }}
        );
        setEmbedUrl(response.data.embedUrl);
        setLoading(false);
      } catch (err) {
        setError('Failed to load dashboard URL');
        setLoading(false);
        console.error('Error fetching QuickSight URL:', err);
      }
    };

    fetchEmbedUrl();
  }, []);

  if (loading) {
    return (
      <div className="riskified-metrics-page">
        <WeblessNavbar />
        <div className="riskified-metrics-container">
          <div className="loading-state">Loading dashboard...</div>
        </div>
        <WeblessFooter />
      </div>
    );
  }

  if (error) {
    return (
      <div className="riskified-metrics-page">
        <WeblessNavbar />
        <div className="riskified-metrics-container">
          <div className="error-state">{error}</div>
        </div>
        <WeblessFooter />
      </div>
    );
  }

  return (
    <div className="riskified-metrics-page">
      <WeblessNavbar />
      <div className="riskified-metrics-container">
        <iframe
          width="100%"
          src={embedUrl}
          title="Riskified Metrics Dashboard"
          frameBorder="0"
        />
      </div>
      <WeblessFooter />
    </div>
  );
};

export default RiskifiedMetrics;
