import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../actions/userActions";
const { jwtDecode } = require("jwt-decode");

interface UserState {
  token: string | null;
  loading: boolean;
  error: string | null;
  userId: string | null;
  role: string | null;
}

const initialState: UserState = {
  token: null,
  loading: false,
  error: null,
  userId: null,
  role: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      try {
        const token = action.payload?.token;
        if (typeof token !== 'string') {
          return state;
        }
        const decoded = jwtDecode(token);
        (window as any).dataLayer?.push({ userId: decoded.user_id });
        
        const newState = { 
          ...state, 
          token: token, 
          userId: decoded.user_id,
          role: action.payload?.role || null
        };
        console.log('Setting user state:', newState);
        return newState;
      } catch (error) {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log('Login fulfilled with payload:', action.payload);
        state.loading = false;
        state.token = action.payload.token;
        state.userId = action.payload.userId;
        state.role = action.payload.role;
        console.log('Updated state after login:', { 
          userId: state.userId, 
          role: state.role 
        });
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
