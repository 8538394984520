import { useDispatch } from "react-redux";
import io from "socket.io-client";
import {
  activateSearch,
} from "../redux/actions/searchActions";

// Establish connection outside the component to avoid multiple connections

const useFetchSummary = (
  setSummary: React.Dispatch<React.SetStateAction<string>>,
  setLoadingSummary: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useDispatch();
  // Function to call for fetching the summary
  const fetchSummary = (queryParams: { [key: string]: string }) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL!;
    const socket = io(backendUrl, {transports: ['websocket']});
    setSummary(""); // Optionally reset summary
    setLoadingSummary(true);

    // Setup listeners only when fetchSummary is called
    socket.on("summary_part", (data: { summary_part: string }) => {
      setSummary((prevSummary) => `${prevSummary}${data.summary_part}`);
      setLoadingSummary(false);
    });

    socket.on("summary_complete", () => {
      setLoadingSummary(false);
      socket.disconnect(); // Disconnect the socket when the stream ends
      dispatch(activateSearch());
    });

    socket.connect(); // Ensure the socket is connected before emitting
    socket.emit("get_summary_with_cache", JSON.stringify(queryParams));

    // Cleanup listeners when the summary is fetched
    return () => {
      socket.off("summary_part");
      socket.off("summary_complete");
    };
  };

  return { fetchSummary }; // Return it so it can be called from your component
};

export default useFetchSummary;
