import React from "react";
import "./Navbar.css";
import Button from "../Buttons/Button";
import { RxHamburgerMenu } from "react-icons/rx";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useCookies } from "react-cookie";
import { FiLogOut } from "react-icons/fi";

const Navbar: React.FC = () => {
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const user = useSelector((state: RootState) => state.user);

  const handleLogout = () => {
    removeCookie('token');
    window.location.reload();
  };

  return (
    <div className="navbar-conatiner">
      <div className="navbar">
        <div>
          <img
            src="https://litmus.io/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flitmus-light.1e3254d6.png&w=128&q=100"
            alt=""
          />
        </div>
        <div className="nav-options">
          <a>Platform</a>
          <a>Resources</a>
          <a>Company</a>
          <a>Contact</a>
        </div>
        <div className="nav-right-container">
          <Button
            text="Book a Demo"
            type="secondary"
            link="https://litmus.io/schedule-a-demo"
          />
          {cookies.token && (
            <button className="logout-btn" onClick={handleLogout}>
              <FiLogOut size={20} />
            </button>
          )}
          <RxHamburgerMenu className="hamburger-icon"/>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
