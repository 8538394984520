import React from "react";
import "./RhomusTile.css"

interface CardProps {
  title: string;
  imgUrl: string;
  desc: string;
  link: string;
}

const RhomusTile: React.FC<CardProps> = ({ title, imgUrl, desc, link }) => {
    
  return (
    <a className="tile rhombus-tile" href={link} target="_blank">
      <div className="rhombus-tile-image-container">
        <img className="rhombus-tile-image" src={imgUrl} alt="" />
      </div>
      <p className="rhombus-title-title">{title}</p>
      <p className="rhombus-title-desc">{desc}</p>
    </a>
  );
};

export default RhomusTile;
