import React from "react";
import "./SuggestedQuestions.css";
import { BsStars } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface SuggestedQuestionProps {
  question: string;
  handleSuggestionClick: Function;
  company?: string;
}

const SuggestedQuestion: React.FC<SuggestedQuestionProps> = ({
  question,
  handleSuggestionClick,
  company,
}) => {
  const { isActive } = useSelector((state: RootState) => state.search);
  return (
    <div
      className={`suggested-question ${company}`}
      onClick={() => {
        if(isActive){
          handleSuggestionClick(question);
          
        }
      }}
    >
      <BsStars className={`suggested-question-star-icon ${company}`} />
      <p>{question}</p>
    </div>
  );
};

export default SuggestedQuestion;
