import { useState } from "react";
import SearchBar from "../SearchBarInputField/SearchbarInputField";
import "./FloatingSearchBar.css";
import SuggestedQuestion from "../SuggestedQuestions/SuggestedQuestions";

interface FloatingSearchBarProps {
  fetchData: Function;
  text: string;
  setText: Function;
}

const FloatingSearchBar: React.FC<FloatingSearchBarProps> = ({
  fetchData,
  text,
  setText,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleSuggestionClick = (text: string) => {
    setText(text);
    fetchData({ text, eventType: "suggested_question_click" });
    setIsHovered(false);
  };

  const handleSearchClick = () => {
    fetchData({ text, eventType: "searchbar_click" });
    setIsHovered(false);
  };

  return (
    <div>
      <div
        className="search-tag-container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="top-questions">
          <SuggestedQuestion
            question={"What are your products?"}
            handleSuggestionClick={handleSuggestionClick}
            company="demo"
          />
          <SuggestedQuestion
            question={"What industry do you serve?"}
            handleSuggestionClick={handleSuggestionClick}
            company="demo"
          />
        </div>
        <div className="suggested-questions-parent-container-wrapper">
          <div className="suggested-questions-parent-container">
            <div>
              <SuggestedQuestion
                question={"Give me top case study?"}
                handleSuggestionClick={handleSuggestionClick}
                company="demo"
              />
            </div>
          </div>
        </div>
        <SearchBar
          fetchData={handleSearchClick}
          text={text}
          setText={setText}
        />
      </div>
      <p className="powered-by-text">Powered by Webless.ai</p>

      <div className={`webless-search-tag ${isHovered ? "hovered" : ""}`}></div>
    </div>
  );
};

export default FloatingSearchBar;
