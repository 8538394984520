import React, { useRef, useState, KeyboardEvent, useEffect } from "react";
import "./SearchBar.css";
import { IoSearch } from "react-icons/io5";
import { VscSend } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import { ACTIVATE_SEARCH } from "../../redux/actionTypes/searchActionTypes";
import {
  activateSearch,
  deactivateSearch,
} from "../../redux/actions/searchActions";
import useEventCapture from "../../hooks/useEventCapture";
import { useLocation } from "react-router-dom";

interface SearchBarProps {
  fetchData: Function;
  text: string;
  setText: Function;
  type?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  fetchData,
  type,
  text,
  setText,
}) => {
  const location = useLocation();
  const buttonRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const { isActive } = useSelector((state: RootState) => state.search);
  const dispatch: AppDispatch = useDispatch();
  const { sendEvent } = useEventCapture();
  const { token } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (text.length > 0) {
      dispatch(activateSearch());
    } else {
      dispatch(deactivateSearch());
    }
  }, [text]);

  const onVisible = () => {
    const companyParam = new URLSearchParams(location.search).get("company");
    // const companyParam = searchParams.get("company");
    sendEvent({
      event_type: "searchbar_visible",
      session_id: sessionStorage.getItem("sessionId") || "",
      token: token || "",
      page: "search_page",
      company: companyParam || "",
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onVisible();
        }
      },
      { threshold: 0.5 }
    );

    if (searchBarRef.current) {
      observer.observe(searchBarRef.current);
    }

    return () => {
      if (searchBarRef.current) {
        observer.unobserve(searchBarRef.current);
      }
    };
  }, []);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isActive) {
      buttonRef.current?.click();
    }
  };

  return (
    <div className={`searchbar ${type}`} ref={searchBarRef}>
      <div className="searchbar-input-field">
        <IoSearch className={`search-icon ${type}`} />
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={handleKeyPress}
          type="text"
          placeholder="Ask me anything"
          className={`search-input ${type}`}
        />
      </div>
      <div
        className={`search-button ${type} ${!isActive && "disabled"}`}
        ref={buttonRef}
        onClick={() => {
          if (isActive) {
            fetchData({ text: text });
          }
        }}
      >
        <VscSend />
      </div>
    </div>
  );
};

export default SearchBar;
