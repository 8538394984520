import React from "react";
import "./SamsaraTile.css";

interface CardProps {
  title: string;
  imgUrl: string;
  desc: string;
  link: string;
}

const SamsaraTile: React.FC<CardProps> = ({ title, imgUrl, desc, link }) => {
  return (
    <a className="tile samsara-tile" href={link} target="_blank">
      <div className="samsara-tile-image-container">
        <img className="samsara-tile-image" src={imgUrl} alt="" />
      </div>
      <div className="samsara-tile-desc-section">
        <p className="samsara-title-title">{title}</p>
        <p className="samsara-title-desc">{desc}</p>
      </div>
    </a>
  );
};

export default SamsaraTile;
