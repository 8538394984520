import React from "react";
import "./SearchTile.css";

interface CardProps {
  title: string;
  imgUrl: string;
  desc: string;
  link: string;
  tileId: string;
  handleTileClick: (link: string, tileId: string) => void;
}

const SearchTile: React.FC<CardProps> = ({
  title,
  imgUrl,
  desc,
  link,
  tileId,
  handleTileClick,
}) => {
  return (
    <div
      className="tile search-tile"
      onClick={() => handleTileClick(link, tileId)}
    >
      <div className="tile-image-container">
        <img className="tile-image" src={imgUrl} alt="" />
        <p className="tile-title">{title}</p>
        <p className="tile-desc">{desc}</p>
      </div>
    </div>
  );
};

export default SearchTile;
