import React, { useState } from "react";
import "./Card.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import Button from "../Buttons/Button";

interface CardProps {
  title: string;
  imgUrl: string;
  desc: string;
  link: string;
}

const Card: React.FC<CardProps> = ({ title, imgUrl, desc, link }) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleExpandableBar = () => {
    setIsOpened(!isOpened);
  };
  return (
    <div className="tile card">
      <img src={imgUrl} alt="img" />
      <p>{title}</p>
      <div className="explandable-bar">
        <div className="expandable-bar-title" onClick={handleExpandableBar}>
          <p>LEARN MORE</p>
          <MdKeyboardArrowDown
            className={`down-arrow ${isOpened ? "rotate" : ""}`}
          />
        </div>
        {isOpened && (
          <div className="expandable-bar-desc">
            <p>{desc}</p>
            <Button text="Learn More" type="secondary" link={link} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
