import React from "react";
import "./PrivacyPolicy.css";
import setFavicon from "../../utils/setFavicon";
import WeblessFooter from "../../components/WeblessFooter/WeblessFooter";
import WeblessNavbar from "../../components/WeblessNavbar/WeblessNavbar";

// PrivacyPolicy main component
const PrivacyPolicy: React.FC = () => {
  let policySections: SectionData[] = [
    {
      title: "1. Introduction",
      content: `At Webless ("we", "our", "us"), your privacy is a top priority. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website, https://webless.ai, including without limitation all sub-domains thereof on which this Privacy Policy is posted (collectively, the “Site”), and use of our software product & services (the "Services"). By using our Site and Services, you agree to the terms of this Privacy Policy.`,
    },
    {
      title: "2. Information We Collect",
      content:
        "We collect personal information that you provide and certain data automatically when you visit our site.",
      subsections: [
        {
          title: "2.1 Information You Provide",
          description:
            "Personal information means any information that may be used to identify an individual, and may include but is not limited to your name, physical or email address, company name, phone number, job title, billing information, profile image, and other information required to provide you with the product, service, or support requested. Depending on the nature of the products and services provided by us to our customers, partners, or suppliers, or the products and services provided to us from our partners or suppliers, we may receive personal information either from you directly or from our customers, partners or suppliers. Some of the ways we may receive personal information include, but are not limited to, when you:",
          items: [
            "Register for an account",
            "Request information about our services",
            "Participate in our surveys",
            "Engage with interactive features of our services",
            "Communicate with us via email, chat, or other channels",
            "Subscribe to notices, announcements, newsletters, or other informational tools",
            "We may attend conferences and collect information from individuals who interact with our services.",
          ],
        },
        {
          title: "2.2 Information We Collect Automatically",
          description:
            "When you visit our Site or use our Services, we may automatically collect certain information. Additionally, we may collect certain information automatically from Visitors when they visit our Customers’ websites and landing pages. This information may include:",
          items: [
            "IP Address",
            "Browser Type and Version",
            "Device Information",
            "Operating System",
            "Pages Visited",
            "Time Spent on Pages",
            "Referring URL",
            "Cookies, Pixel Tag, and Similar Technologies",
          ],
        },
        {
          title: "2.3 Customer-Provided Information about Visitors",
          description:
            "Customer-Provided Information about Visitors: As part of our commitment to offering advanced personalization of websites and landing pages, Webless may receive additional data from our Customers to enhance the user experience for their Visitors. Customers can share valuable insights with us, which allow for a more tailored and relevant interaction for each Visitor. \n This information can include both aggregated and individual data points, such as:",
          description2:
            "By leveraging this detailed Visitor information, Webless enables Customers to dynamically adjust and personalize the content, offers, and messaging that each Visitor sees, resulting in a more engaging and effective user experience tailored to the Visitor’s specific needs and behaviors.",
          items: [
            "Browsing Patterns: Insights into the specific pages a Visitor has viewed, the amount of time spent on each page, and their overall navigation path on the Customer’s website.",
            "Communication History: Information on the last interaction a Visitor had with the Customer, whether through emails, chats, or other communication channels.",
            "Event Triggers: Data on actions that a Visitor takes on the site, such as query submissions, form submissions, downloads, or other engagements that indicate their interest levels.",
            "Demographic and Behavioral Data: Information such as the Visitor’s location, device type, or other demographic details that can help in delivering a more personalized experience.",
            "Purchase Behavior: Details on when a Visitor made a purchase on the Customer's website, including the types of products or services bought.",
          ],
        },
        {
          title: "2.4 Information from Other Sources",
          description:
            "Webless may obtain information about a Customer from various sources, including third-party services and organizations, to supplement the information provided by the Customer. This supplemental information may include details such as the Customer’s company name associated with an IP address, number of employees, industry information, and business email addresses linked to the Customer. This information helps Webless verify the data provided by the Customer and enhances our ability to deliver our Services effectively.",
          description2:
            "We may use Google Analytics and other service providers to gather information about Visitor behavior and demographics. This helps us understand how Visitors interact with our platform and allows us to improve the Services we offer. For more details about how Google Analytics collects and processes data, please visit www.google.com/policies/privacy/partners/.",
        },
        {
          title: "3. How We Use Your Information",
          description:
            "We use the information we collect, including any additional data provided by our Customers, for various purposes, including:",
          items: [
            "Providing and Managing Services: To deliver the personalized services you request, manage your account, and tailor your experience on our platform. This includes using data provided by our Customers to customize the content, offers, and messaging that Visitors see on their websites and landing pages.",
            "Personalization: To enhance the personalization features of our Services by leveraging the additional Visitor data provided by our Customers. This helps ensure that Visitors receive content and experiences that are relevant and engaging based on their behavior and interactions with the Customer’s website.",
            "Communication: To send you updates, newsletters, marketing communications, and other information that may be of interest to you. This may include personalized communications based on the insights we gather from the additional data provided.",
            "Improvement and Development: To analyze how our Site and Services are used, including the effectiveness of personalized content and features, and to continuously improve and develop new offerings that better serve our Customers and their Visitors.",
            "Analytics: To conduct analytics and gather insights on the performance of our Services, including the personalized content delivered to Visitors. We analyze trends, Visitor behavior, and the effectiveness of personalized features to optimize our platform and ensure that we are providing the best possible service to our Customers.",
            "Security: To monitor and prevent fraud, abuse, and other threats, ensuring that both our platform and the personalized experiences we offer remain secure and trustworthy.",
            "Legal Compliance: To comply with legal obligations and enforce our terms and policies, ensuring that we operate within the bounds of applicable laws and regulations.",
          ],
        },
        {
          title: "4. Sharing Your Information",
          description:
            "We may share your information with third parties in the following circumstances:",
          items: [
            "Service Providers: We may share your information with vendors, consultants, and other service providers who need access to such information to perform work on our behalf.",
            "Business Transfers: If we undergo a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.",
            "Legal Obligations: We may disclose your information to comply with legal processes, respond to lawful requests from public authorities, or protect our rights and interests.",
            "Consent: We may share your information with your consent or at your direction.",
          ],
        },
        {
          title: "5. Cookies and Tracking Technologies",
          description:
            "We use cookies and similar tracking technologies to collect and store information when you interact with our Site and Services. You can manage your cookie preferences through your browser settings, but note that disabling cookies may affect the functionality of our Site and Services.",
        },
        {
          title: "6. Data Security",
          description:
            "We implement industry-standard security measures to protect your personal information. However, no security system is impenetrable, and we cannot guarantee the absolute security of your data.",
        },
        {
          title: "7. Your Data Protection Rights",
          description:
            "Depending on your location, you may have certain rights regarding your personal information, including:",
          items: [
            "Access: The right to access your personal data and receive a copy of it.",
            "Rectification: The right to request corrections to any inaccuracies in your data.",
            "Erasure: The right to request deletion of your personal data, subject to certain exceptions.",
            "Restriction: The right to request the restriction of processing your personal data.",
            "Objection: The right to object to the processing of your personal data under certain conditions.",
            "Data Portability: The right to request the transfer of your data to another organization or directly to you.",
          ],
        },
        {
          title: "8. Changes to This Privacy Policy",
          description: `We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our Site and updating the "Last Updated" date.`,
        },
        {
          title: "9. Contact Us",
          description:
            "If you have any questions about this Privacy Policy or our data practices, please contact us at: contact@webless.ai",
        },
      ],
    },
  ];

  setFavicon("/assets/images/webless_favicon.jpg");
  return (
    <>
      <WeblessNavbar/>
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        {policySections.map((section, index) => (
          <div key={index}>
            <h2>{section.title}</h2>
            <p>{section.content}</p>
            {section.subsections?.map((subsection, idx) => (
              <ListSection
                key={idx}
                title={subsection.title}
                description={subsection.description}
                description2={subsection.description2}
                items={subsection.items}
                isOrdered={subsection.isOrdered}
              />
            ))}
          </div>
        ))}
      </div>
    <WeblessFooter/>
    </>
  );
};

export default PrivacyPolicy;

// Define interfaces for section props
interface ListSectionProps {
  title: string;
  description: string;
  description2?: string;
  items?: string[];
  isOrdered?: boolean;
}

interface PrivacyPolicyProps {
  policySections: SectionData[];
}

interface SectionData {
  title: string;
  content: string;
  content2?: string;
  subsections?: ListSectionProps[];
}

// ListSection component for rendering list items
const ListSection: React.FC<ListSectionProps> = ({
  title,
  description,
  description2,
  items,
  isOrdered = false,
}) => (
  <section>
    <h2>{title}</h2>
    <p>{description}</p>
    {items &&
      (isOrdered ? (
        <ol>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      ) : (
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ))}
    {description2 && <p>{description2}</p>}
  </section>
);
