import { Link } from "react-router-dom";
import "./WeblessNavbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import CalendlyPopupButton from "../Calendly/CalendlyPopupButton";
import { FiLogOut } from "react-icons/fi";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const WeblessNavbar = () => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const user = useSelector((state: RootState) => state.user);

  const handleLogout = () => {
    removeCookie('token');
    window.location.reload();
  };

  return (
    <nav className="navbar-container">
      <div className="navbar-left-container">
        <div className="nav-logo-container">
          <a href="https://webless.ai/" target="_blank">
            <img src="/assets/images/webless_logo.jpg" alt="Webless Logo" />
          </a>
        </div>
      </div>

      <div className="navbar-container-right">
        <div className="navbar-right-container-hamburger">
          <GiHamburgerMenu onClick={() => setIsOptionsOpen((prev) => !prev)} />
          {isOptionsOpen && (
            <div className="hamburger-options">
              <CalendlyPopupButton className="filled" />
              {user.token && (
                <button onClick={handleLogout} className="hamburger-link logout-btn">
                  Logout
                </button>
              )}
            </div>
          )}
        </div>
        <div className="navbar-right-container-buttons-wrapper">
          <CalendlyPopupButton className="filled" />
          {user.token && (
            <button onClick={handleLogout} className="logout-btn desktop-logout">
              <FiLogOut size={20} />
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default WeblessNavbar;
