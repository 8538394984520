import React from "react";
import useDocumentTitle from "../../hooks/useDocumentTitle.tsx";

const NotFound: React.FC = () => {
  useDocumentTitle("404 Page");
  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
