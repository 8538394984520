import React from "react";
import "./RevvanaTile.css";

interface CardProps {
  title: string;
  imgUrl: string;
  desc: string;
  link: string;
}

const RevvanaTile: React.FC<CardProps> = ({ title, imgUrl, desc, link }) => {
  return (
    <a className="tile revvana-tile" href={link} target="_blank">
      <div className="revvana-tile-image-container">
        <img className="revvana-tile-image" src={imgUrl} alt="" />
      </div>
      <p className="revvana-title-title">{title}</p>
      <p className="revvana-title-desc">{desc}</p>
    </a>
  );
};

export default RevvanaTile;
