import { signInWithEmailAndPassword } from "firebase/auth";
import { SET_USER, LOGOUT_USER } from "../actionTypes/userActionTypes";
import { auth, db } from "../../firebse";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from 'firebase/firestore';

export const loginUser = createAsyncThunk(
  "user/login",
  async (
    { email, password }: { email: string; password: string },
    thunkAPI
  ) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();
      const userId = userCredential.user.uid;

      try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const userRole = userData.role;

          // Store role in cookie
          document.cookie = `userRole=${userRole}; path=/`;
          
          return { 
            token, 
            userId,
            role: userRole
          };
        } else {
          return { token, userId, role: null };
        }
      } catch (firestoreError) {
        console.error('Firestore Error:', firestoreError);
        return { token, userId, role: null };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
