import React from "react";
import "./Button.css";

interface ButtonProps {
  text: string;
  type: "primary" | "secondary" | "rohmbus" | "revvana" | "samsara";
  link: string;
}

const Button: React.FC<ButtonProps> = ({ text, type, link }) => {
  const handleButtonClick = () => {
    window.open(link, "_blank");
  };

  return (
    <div className={`button ${type}`} onClick={handleButtonClick}>
      <p>{text}</p>
    </div>
  );
};

export default Button;
