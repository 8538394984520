import React from "react";
import "./Footer.css";
import { FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-top-container">
        <div className="left-container">
          <div className="contact-us">
            <div className="footer-logo">
              <img
                src="https://litmus.io/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flitmus-dark.ddc5fb5c.png&w=128&q=100"
                alt=""
              />
            </div>
            <p className="footer-footer-logo-desc">
              Making Industrial Data Make Sense for OT, IT and the Entire
              Enterprise
            </p>
            <div className="icons-row">
              <FaLinkedin />
              <FaXTwitter />
              <FaYoutube />
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="links-section">
            <a className="footer-links-title">Platform</a>
            <a className="footer-links">Litmus Edge</a>
            <a className="footer-links">Litmus Edge Manager</a>
            <a className="footer-links">Solution Accelerators</a>
            <a className="footer-links">Integrations</a>
          </div>
          <div className="links-section">
            <a className="footer-links-title">Support</a>
            <a className="footer-links">Documentation</a>
            <a className="footer-links">Litmus Academy</a>
            <a className="footer-links">Support Center</a>
            <a className="footer-links">Speak With Sales</a>
            <a className="footer-links">Pricing</a>
          </div>

          <div className="links-section">
            <a className="footer-links-title">Company</a>
            <a className="footer-links">Who We Are</a>
            <a className="footer-links">What's New?</a>
            <a className="footer-links">Newsroom</a>
            <a className="footer-links">Careers</a>
            <a className="footer-links">Partners</a>
          </div>
          <div className="links-section">
            <a className="footer-links-title">Resources</a>

            <a className="footer-links">Blog</a>
            <a className="footer-links">Customer Stories</a>
            <a className="footer-links">Central Portal</a>
            <a className="footer-links">Webinars & Events</a>
            <a className="footer-links">Litmus YouTube Channel</a>
          </div>
        </div>
      </div>
      <div className="footer-bottom-container">
        <p className="offices-text">
          Worldwide Offices Silicon Valley • Toronto • Munich • Tokyo
        </p>
        <div className="copy-right-row">
          <p className="">© 2024 Litmus Automation Inc</p>
          <p className="primary-color">•</p>
          <p>All Rights Reserved</p>
        </div>
        <div className="policy-terms-container">
          <p>Privacy Policy</p>
          <p>•</p>
          <p>Terms of Use</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
