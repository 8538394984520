import React, { useEffect, useRef, useState } from "react";
import "./Demo.css";
import useFetchSummary from "../../hooks/useFetchSummary";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  activateSearch,
  deactivateSearch,
} from "../../redux/actions/searchActions";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import WeblessNavbar from "../../components/WeblessNavbar/WeblessNavbar";
import WeblessFooter from "../../components/WeblessFooter/WeblessFooter";
import Spinner from "../../components/Loaders/Spinner/Spinner";
import SearchTile from "../../components/Tiles/SearchTile/SearchTile";
import FloatingSearchBar from "../../components/FloatingSearchBar/FloatingSearchBar";
import Summary from "../../components/Summary/Summary";
import Masonry from "react-masonry-css";
import HorizontalLoader from "../../components/Loaders/HorizontalLoader/HorizontalLoader";
import ShowMoreText from "../../components/ShowMoreText/ShowMoreText";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useSelector } from "react-redux";
import CalendlyPopupButton from "../../components/Calendly/CalendlyPopupButton";
import useEventCapture from "../../hooks/useEventCapture";

interface ResponseItem {
  title: string;
  description: string;
  link: string;
  image: string;
  id: string;
}

interface MarketingMessage {
  link: string;
  message: string;
}

interface Data {
  responses: ResponseItem[];
  marketing_message: MarketingMessage;
}

interface Conversation {
  question: string;
  summary: string;
  answer: Data;
}

interface ErrorType {
  message: string | null;
  status: number | null;
}
const Demo = () => {
  const [conversation, setConversation] = useState<Conversation[]>([]);
  const [text, setText] = useState("");
  const [data, setData] = useState<Data>({
    responses: [],
    marketing_message: { link: "", message: "" },
  });
  const [tone, setTone] = useState<string>("");
  const [generationType, setGenerationType] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [summary, setSummary] = useState<string>("");
  const [numTiles, setNumTiles] = useState("");
  const [userID, setUserID] = useState("");
  const [sessionID, setSessionID] = useState("");
  const [numHighlights, setNumHighlights] = useState("");
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType>({
    message: null,
    status: null,
  });
  const { fetchSummary } = useFetchSummary(setSummary, setLoadingSummary);
  const [loading, setLoading] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const spinnerRef = useRef<HTMLDivElement | null>(null);
  const flag = useRef(true);
  const dispatch: AppDispatch = useDispatch();
  const width = useWindowWidth();
  const { token } = useSelector((state: RootState) => state.user);
  const [requestId, setRequestId] = useState<string>("");
  const { sendEvent } = useEventCapture();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tempSessionId = searchParams.get("sessionId") || uuidv4();
    let temp = sessionStorage.getItem("sessionId");
    const companyParam = searchParams.get("company");
    if (temp !== null) {
      setSessionID(temp);
    } else {
       sendEvent({
         event_type: "session_start",
         session_id: tempSessionId,
         token: token || "",
         page: "demo_page",
         company: companyParam || "",
       });
      sessionStorage.setItem("sessionId", tempSessionId);
      setSessionID(tempSessionId);
    }
  }, []);

  useEffect(() => {
    if (conversation.length > 0) {
      setConversation((prev) => {
        let temp = [...prev];
        let removed: Conversation = temp.pop() || {
          question: "",
          summary: "",
          answer: {
            responses: [],
            marketing_message: { link: "", message: "" },
          },
        };
        if (removed) {
          removed.summary = summary;
        }

        return [...temp, removed];
      });
    }
  }, [summary]);

  const locationQuery = () => {
    return new URLSearchParams(window.location.search);
  };

  useEffect(() => {
    if (containerRef.current) {
      const lastChild = containerRef.current.querySelector(
        ".single-result-container:last-child"
      );
      if (lastChild) {
        const lastChildRect = lastChild.getBoundingClientRect();
        const offset = lastChildRect.top - 6 * 16;
        window.scrollBy({ top: offset, behavior: "smooth" });
      }
    }
  }, [conversation]);

  useEffect(() => {
    if (spinnerRef.current) {
      spinnerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [loading]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const toneParameter = searchParams.get("tone");
    const generationTypeParameter = searchParams.get("generation_type");
    const modelParameter = searchParams.get("model");
    const companyParameter = searchParams.get("company");
    const numTilesParameter = searchParams.get("n");
    const userParameter = searchParams.get("user_id");

    if (toneParameter) {
      setTone(toneParameter);
      // Do something with the parameter value
    }
    if (generationTypeParameter) {
      setGenerationType(generationTypeParameter);
      // Do something with the parameter value
    }
    if (modelParameter) {
      setModel(modelParameter);
      // Do something with the parameter value
    }
    if (companyParameter) {
      setCompany(companyParameter);
      // Do something with the parameter value
    }
    if (numTilesParameter) {
      setNumTiles(numTilesParameter);
      // Do something with the parameter value
    }
    if (userParameter) {
      setUserID(userParameter);
      // Do something with the parameter value
    }
  }, [window.location.search]);

  const fetchData = async ({
    text,
    eventType,
  }: {
    text: string;
    eventType: string;
  }) => {
    const requestID = uuidv4();
    setRequestId(requestID);
    try {
      (window as any).dataLayer?.push({ requestId: requestID });
      dispatch(deactivateSearch());
      setLoading(true);
      const queryParams: { [key: string]: string } = {
        query: text,
        requestId: requestID,
      };
      if (company !== "") {
        queryParams["company"] = company;
      }
      if (tone !== "") {
        queryParams["tone"] = tone;
      }
      if (generationType !== "") {
        queryParams["generation_type"] = generationType;
      }
      if (model !== "") {
        queryParams["model"] = model;
      }
      if (numTiles !== "") {
        queryParams["number_of_results"] = numTiles;
      }
      if (numHighlights !== "") {
        queryParams["num_highlights"] = numHighlights;
      }
      if (userID !== "") {
        queryParams["user_id"] = userID;
      }
      if (token) {
        queryParams["token"] = token;
      }
      if (sessionID) {
        queryParams["sessionId"] = sessionID;
      }

      sendEvent({
        event_type: eventType,
        session_id: sessionStorage.getItem("sessionId") || "",
        token: token || "",
        page: "demo_page",
        company: company,
        request_id: requestID,
        query: text,
      });

      const res = await axios.post<Data>(
        `${process.env.REACT_APP_BACKEND_URL}/post_query`,
        queryParams
      );
      setData(res.data);
      setConversation((prev) => {
        let temp = [
          ...prev,
          {
            question: text,
            summary: summary,
            answer: res.data,
          },
        ];
        setText("");
        return temp;
      });
      fetchSummary(queryParams);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError({
          status: e.status || null,
          message: e.message || null,
        });
      } else {
        setError({ message: "An unexpected error occurred", status: 500 });
      }
      setLoading(false);
      dispatch(activateSearch());
      alert(error?.message);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTileClick = (link: string, tileId: string) => {
    sendEvent({
      event_type: "tile_click",
      session_id: sessionStorage.getItem("sessionId") || "",
      token: token || "",
      page: "demo_page",
      company: company,
      request_id: requestId,
      query: text,
      tileId: tileId,
    });

    window.open(link, "_blank");
  };

  return (
    <div className="demo-page">
      <WeblessNavbar />
      <FloatingSearchBar fetchData={fetchData} text={text} setText={setText} />
      <div className="demo-parent-container">
        {conversation.length == 0 && !loading && (
          <section className="quotes-container">
            <p className="quotes-container-heading">
              Step into the future of digital identity and access management
            </p>
            <p className="quotes-container-subtext">Try asking any question</p>
            <div className="down-arrow">
              <img src="/assets/images/downArrow.svg" alt="" />
            </div>
          </section>
        )}

        {conversation.length > 0 && (
          <section>
            <div className="conversation-container" ref={containerRef}>
              {conversation.map((result) => {
                return (
                  <div className="single-result-container">
                    <div className="top-section">
                      <div className="question">
                        <h1 className="question-title">{result.question}</h1>
                      </div>
                      <p className="result-desc-demo">
                        {loadingSummary || loading ? (
                          <HorizontalLoader />
                        ) : width < 765 ? (
                          <ShowMoreText
                            text={summary}
                            maxLength={200}
                            type={generationType}
                          />
                        ) : generationType == "bullet_point" ? (
                          <ul className="bullet-point-summary">
                            {summary.split("###").map((item) => {
                              let temp = item.trim();
                              if (temp.length > 0) {
                                return <li>{item}</li>;
                              }
                            })}
                          </ul>
                        ) : (
                          summary
                        )}
                        {summary.length > 0 && !loadingSummary && (
                          <>
                            <br />
                            <div
                              className="summary-btn"
                              onClick={() => {
                                sendEvent({
                                  event_type: "click_cta",
                                  session_id: sessionID,
                                  token: token || "",
                                  page: "demo_page",
                                  company: company,
                                  request_id: requestId,
                                });
                              }}
                            >
                              <CalendlyPopupButton
                                className="filled"
                                text="Get started"
                              />
                            </div>
                          </>
                        )}
                      </p>
                    </div>
                    <div className="result-section">
                      <Masonry
                        breakpointCols={{ default: 3, 700: 2, 500: 1 }}
                        className="my-masonry-grid"
                        columnClassName="masonry-grid-column"
                      >
                        {result.answer.responses.map((item, i) => {
                          return (
                            <SearchTile
                              key={item.id}
                              title={item.title}
                              desc={item.description}
                              imgUrl={item.image}
                              link={item.link}
                              tileId={item.id}
                              handleTileClick={handleTileClick}
                            />
                          );
                        })}
                      </Masonry>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}
        {loading && (
          <div
            className={`spinner-container ${
              conversation.length == 0 && "first-load"
            }`}
            ref={spinnerRef}
          >
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          </div>
        )}
      </div>
      <WeblessFooter />
    </div>
  );
};

export default Demo;
