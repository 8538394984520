import React, { useState } from 'react';

const Pipeline: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [sampleSize, setSampleSize] = useState(100); // Default value

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    formData.append('sample_size', sampleSize.toString());

    setIsLoading(true);
    setStatus('<div style="color: #3b82f6; text-align: center;">Starting pipeline...</div>');

    try {
      const response = await fetch('https://d36qnz2jyrj457.cloudfront.net/invoke-pipeline', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (result.status === 'success') {
        const linkHtml = result.webless_link.startsWith('http')
          ? `<a href="${result.webless_link}" target="_blank" style="color: #3b82f6; text-decoration: underline;">${result.webless_link}</a>`
          : `<span style="color: #4b5563; font-style: italic;">${result.webless_link}</span>`;

        setStatus(`
          <div style="background-color: #ecfdf5; border: 1px solid #d1fae5; color: #047857; padding: 20px; border-radius: 10px;">
            <p style="font-weight: 600;">Pipeline started successfully!</p>
            <p style="margin-top: 10px; font-size: 14px;">Request UUID: ${result.request_uuid}</p>
            <p style="margin-top: 10px; font-size: 14px;">Execution ARN: ${result.execution_arn}</p>
            <p style="margin-top: 10px; font-size: 14px;">Using model: ${formData.get('model_name')}</p>
            <p style="margin-top: 10px; font-size: 14px;">Sample Size: ${sampleSize} URLs</p>
            <div style="margin-top: 20px; font-size: 14px;">
              Your content will be available at:<br/>
              ${linkHtml}
            </div>
            <p style="margin-top: 20px; font-size: 12px; color: #6b7280;">Note: The link will be active after pipeline completion</p>
          </div>
        `);
        form.reset();
        setSampleSize(100); // Reset to default
      } else {
        throw new Error(result.message || 'Failed to start pipeline');
      }
    } catch (error) {
      setStatus(`
        <div style="background-color: #fef2f2; border: 1px solid #fecaca; color: #b91c1c; padding: 20px; border-radius: 10px;">
          Error: ${(error as Error).message}
        </div>
      `);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    const form = document.getElementById('pipelineForm') as HTMLFormElement;
    if (form) {
      form.reset();
    }
    setStatus(null);
    setSampleSize(100); // Reset to default
  };

  return (
    <div style={{ maxWidth: '600px', margin: '40px auto', padding: '0px', backgroundColor: '#f9fafb', borderRadius: '12px' }}>
      <div style={{ backgroundColor: '#ffffff', padding: '30px', borderRadius: '12px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)' }}>
        <h1 style={{ fontSize: '26px', fontWeight: '700', marginBottom: '30px', textAlign: 'center', color: '#1f2937' }}>Company Ingestion Pipeline</h1>
        <form id="pipelineForm" onSubmit={handleSubmit}>
          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', fontSize: '16px', fontWeight: '600', marginBottom: '10px', color: '#374151' }}>Company Name</label>
            <input
              type="text"
              name="company_name"
              required
              style={{ width: '100%', padding: '12px', border: '1px solid #d1d5db', borderRadius: '6px', fontSize: '14px', boxSizing: 'border-box' }}
              placeholder="Enter company name"
            />
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', fontSize: '16px', fontWeight: '600', marginBottom: '10px', color: '#374151' }}>Sitemap URL</label>
            <input
              type="url"
              name="sitemap_url"
              required
              style={{ width: '100%', padding: '12px', border: '1px solid #d1d5db', borderRadius: '6px', fontSize: '14px', boxSizing: 'border-box' }}
              placeholder="https://example.com/sitemap.xml"
            />
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', fontSize: '16px', fontWeight: '600', marginBottom: '10px', color: '#374151' }}>Products</label>
            <input
              type="text"
              name="products"
              required
              style={{ width: '100%', padding: '12px', border: '1px solid #d1d5db', borderRadius: '6px', fontSize: '14px', boxSizing: 'border-box' }}
              placeholder="Product A, Product B, Product C"
            />
            <p style={{ marginTop: '10px', fontSize: '13px', color: '#6b7280' }}>Enter product names separated by commas</p>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', fontSize: '16px', fontWeight: '600', marginBottom: '10px', color: '#374151' }}>Model</label>
            <select
              name="model_name"
              style={{ width: '100%', padding: '12px', border: '1px solid #d1d5db', borderRadius: '6px', fontSize: '14px', boxSizing: 'border-box' }}
            >
              <option value="anthropic.claude-3-haiku-20240307-v1:0">Haiku</option>
              <option value="anthropic.claude-3-5-sonnet-20240620-v1:0">Sonnet 3.5</option>
            </select>
            <p style={{ marginTop: '10px', fontSize: '13px', color: '#6b7280' }}>Select the model to use for processing</p>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', fontSize: '16px', fontWeight: '600', marginBottom: '10px', color: '#374151' }}>
              Sample Size: {sampleSize} URLs
            </label>
            <input
              type="range"
              min="100"
              max="500"
              step="100"
              value={sampleSize}
              onChange={(e) => setSampleSize(Number(e.target.value))}
              style={{ width: '100%' }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', fontSize: '12px', color: '#6b7280' }}>
              <span>100</span>
              <span>200</span>
              <span>300</span>
              <span>400</span>
              <span>500</span>
            </div>
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              type="submit"
              style={{ 
                flex: '1',
                backgroundColor: '#2563eb',
                color: '#ffffff',
                padding: '14px',
                borderRadius: '6px',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: '600',
                transition: 'background-color 0.3s'
              }}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#1d4ed8')}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#2563eb')}
              disabled={isLoading}
            >
              {isLoading ? (
                <span style={{
                  border: '3px solid #f3f3f3',
                  borderTop: '3px solid #3498db',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'inline-block',
                  marginRight: '10px',
                  animation: 'spin 1s linear infinite'
                }}></span>
              ) : 'Start Pipeline'}
            </button>
            <button
              type="button"
              onClick={handleReset}
              style={{
                flex: '1',
                backgroundColor: '#e5e7eb',
                color: '#374151',
                padding: '14px',
                borderRadius: '6px',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: '600',
                transition: 'background-color 0.3s'
              }}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#d1d5db')}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#e5e7eb')}
            >
              Reset
            </button>
          </div>
        </form>

        {status && (
          <div
            id="status"
            style={{ marginTop: '20px' }}
            dangerouslySetInnerHTML={{ __html: status }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Pipeline;