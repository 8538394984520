import {
  ACTIVATE_SEARCH,
  DEACTIVATE_SEARCH,
} from "../actionTypes/searchActionTypes";

const initialState = {
  isActive: true,
};

const searchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTIVATE_SEARCH:
      return {
        ...state,
        isActive: true,
      };
    case DEACTIVATE_SEARCH:
      return {
        ...state,
        isActive: false,
      };
    default:
      return state;
  }
};

export default searchReducer;
