import React from 'react';
import './SkeletonLoader.css';

const SkeletonLoader: React.FC = () => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-tile">
        <div className="skeleton-image pulse"></div>
        <div className="skeleton-content">
          <div className="skeleton-title pulse"></div>
          <div className="skeleton-text pulse"></div>
          <div className="skeleton-text pulse"></div>
          <div className="skeleton-text-short pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
