import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar/SearchBar";
import "./Litmus.css";
import Button from "../../components/Buttons/Button";
import Card from "../../components/Tiles/Card";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import useFetchSummary from "../../hooks/useFetchSummary";
import Navbar from "../../components/Navbar/Navbar";
import AddEmailSection from "../../components/AddEmailSection/AddEmailSection";
import DeploymentModelSection from "../../components/DeploymentModelSection/DeploymentModelSection";
import Footer from "../../components/Footer/Footer";
import Spinner from "../../components/Loaders/Spinner/Spinner";
import HorizontalLoader from "../../components/Loaders/HorizontalLoader/HorizontalLoader";
import ShowMoreText from "../../components/ShowMoreText/ShowMoreText";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useLocation } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle.tsx";
import { useDispatch } from "react-redux";
import { activateSearch, deactivateSearch } from "../../redux/actions/searchActions";

interface ResponseItem {
  title: string;
  description: string;
  link: string;
  image: string;
}

interface MarketingMessage {
  link: string;
  message: string;
}

interface Data {
  responses: ResponseItem[];
  marketing_message: MarketingMessage;
}

interface ErrorType {
  message: string | null;
  status: number | null;
}

const Litmus = () => {
  const [text, setText] = useState("");
  const [data, setData] = useState<Data>({
    responses: [],
    marketing_message: { link: "", message: "" },
  });
  const location = useLocation();
  const [tone, setTone] = useState<string>("");
  const [generationType, setGenerationType] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [summary, setSummary] = useState<string>("");
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const { fetchSummary } = useFetchSummary(setSummary, setLoadingSummary);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType>({
    message: null,
    status: null,
  });
  const width = useWindowWidth();
  const { token } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  useDocumentTitle("Litmus");

  const fetchData = async ({ text }: { text: string }) => {
    const requestID = uuidv4();
    try {
      (window as any).dataLayer?.push({ requestId: requestID });
      dispatch(deactivateSearch());
      setLoading(true);
      const queryParams: { [key: string]: string } = {
        query: text,
        company: "litmus",
        requestId: requestID,
      };
      if (tone !== "") {
        queryParams["tone"] = tone;
      }
      if (generationType !== "") {
        queryParams["generation_type"] = generationType;
      }
      if (model !== "") {
        queryParams["model"] = model;
      }
      if (token) {
        queryParams["token"] = token;
      }
      const res = await axios.post<Data>(
        `${process.env.REACT_APP_BACKEND_URL}/post_query`,

        queryParams
      );
      setData(res.data);
      fetchSummary(queryParams);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError({
          status: e.status || null,
          message: e.message || null,
        });
      } else {
        setError({ message: "An unexpected error occurred", status: 500 });
      }
      setLoading(false);
      dispatch(activateSearch());
      alert(error?.message);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const toneParameter = searchParams.get("tone");
    const generationTypeParameter = searchParams.get("generation_type");
    const modelParameter = searchParams.get("model");

    if (toneParameter) {
      setTone(toneParameter);
      // Do something with the parameter value
    }
    if (generationTypeParameter) {
      setGenerationType(generationTypeParameter);
      // Do something with the parameter value
    }
    if (modelParameter) {
      setModel(modelParameter);
      // Do something with the parameter value
    }
  }, [location.search]);

  return (
    <div>
      <Navbar />
      <div className="top-container">
        <SearchBar fetchData={fetchData} text={text} setText={setText} />
        <p className="result-desc">
          {loadingSummary || loading ? (
            <HorizontalLoader type="litmus" />
          ) : width < 765 ? (
            <ShowMoreText text={summary} maxLength={200} />
          ) : (
            summary
          )}
        </p>
        {loading == false &&
          loadingSummary == false &&
          data.responses.length > 0 && (
            <div className="buttons-container">
              <Button
                text="Get Started"
                type="primary"
                link="https://litmus.io/contact-us"
              />
            </div>
          )}
      </div>
      <div className="cards-parent-container">
        {loading == true ? (
          <div className="spinner-container">
            <Spinner type="litmus" />
          </div>
        ) : (
          <div className="cards-container">
            {data.responses.map((item, i) => (
              <Card
                key={i}
                title={item.title}
                desc={item.description}
                link={item.link}
                imgUrl={item.image}
              />
            ))}
          </div>
        )}
      </div>
      {/* <DeploymentModelSection />
      <div className="add-email-section-container">
        <AddEmailSection />
      </div> */}
      <Footer />
    </div>
  );
};

export default Litmus;
