import {
  ACTIVATE_SEARCH,
  DEACTIVATE_SEARCH,
} from "../actionTypes/searchActionTypes";

export const activateSearch = () => ({
  type: ACTIVATE_SEARCH,
});

export const deactivateSearch = () => ({
  type: DEACTIVATE_SEARCH,
});
