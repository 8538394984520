import { useRef, KeyboardEvent, useState, useEffect } from "react";
import "./SearchbarInputField.css";
// import searchIcon from "../../assets/search-icon.svg";
// import sendIcon from "../../assets/send-icon.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CiSearch } from "react-icons/ci";
import useEventCapture from "../../hooks/useEventCapture";
import { useLocation } from "react-router-dom";

interface SearchBarProps {
  fetchData: Function;
  text: string;
  setText: Function;
}

const SearchBar: React.FC<SearchBarProps> = ({ fetchData, text, setText }) => {
  const [inputText, setInputText] = useState("");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { isActive } = useSelector((state: RootState) => state.search);
  const { sendEvent } = useEventCapture();
  const { token } = useSelector((state: RootState) => state.user);

  const onVisible = () => {
    const companyParam = new URLSearchParams(location.search).get("company");
    sendEvent({
      event_type: "searchbar_visible",
      session_id: sessionStorage.getItem("sessionId") || "",
      token: token || "",
      page: "demo_page",
      company: companyParam || "",
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onVisible();
        }
      },
      { threshold: 0.5 }
    );

    if (searchBarRef.current) {
      observer.observe(searchBarRef.current);
    }

    return () => {
      if (searchBarRef.current) {
        observer.unobserve(searchBarRef.current);
      }
    };
  }, []);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isActive) {
      buttonRef.current?.click();
    }
  };

  return (
    <div className="search-bar-container" ref={searchBarRef}>
      <div className="search-icon">
        <img src="/assets/images/send-icon.svg" alt="" />
      </div>
      <input
        type="text"
        value={inputText}
        onChange={(e) => {
          setInputText(e.target.value);
          setText(e.target.value);
        }}
        onKeyPress={handleKeyPress}
        placeholder="Ask any question..."
      />
      <button
        className={`search-button ${text.length == 0 && "disabled"}`}
        ref={buttonRef}
        disabled={text.length === 0}
        onClick={() => {
          if (isActive) {
            fetchData({ text: text });
            setInputText("");
          }
        }}
      >
        <CiSearch />
      </button>
    </div>
  );
};

export default SearchBar;
