import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRoles?: string[] | 'all';
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles = 'all'
}) => {
  const { token, role } = useSelector((state: RootState) => state.user);

  if (!token) {
    return <Navigate to="/" replace />;
  }

  // If allowedRoles is 'all' or empty array, allow any authenticated user
  if (allowedRoles === 'all' || (Array.isArray(allowedRoles) && allowedRoles.length === 0)) {
    return <>{children}</>;
  }

  // Otherwise, check if user's role is in the allowed roles array
  if (Array.isArray(allowedRoles) && !allowedRoles.includes(role || '')) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
