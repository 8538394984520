import { useEffect, useState } from "react";
import "./App.css";
import AllRoutes from "./routes/AllRoutes";
import { initializeGTM } from "./gtm";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import LoginPopup from "./components/Popup/LoginPopup";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { setUser } from "./redux/reducers/userReducer";

function App() {
  const [showPopup, setShowPopup] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const [cookies] = useCookies(["token", "userRole"]);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    initializeGTM(); // Initialize Google Tag Manager
  }, []);

  useEffect(() => {
    if (cookies?.token?.token) {
      dispatch(setUser({ 
        token: cookies.token.token,
        role: cookies.userRole || null
      }));
    }
  }, [cookies, dispatch]);

  useEffect(() => {
    if (user.token == null) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [user]);

  return (
    <div className="App">
      {showPopup ? <LoginPopup /> : <AllRoutes />}
    </div>
  );
}

export default App;
