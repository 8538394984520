import React, { useEffect, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import useFetchSummary from "../../hooks/useFetchSummary";
import useWindowWidth from "../../hooks/useWindowWidth";
import Spinner from "../../components/Loaders/Spinner/Spinner";
import ShowMoreText from "../../components/ShowMoreText/ShowMoreText";
import HorizontalLoader from "../../components/Loaders/HorizontalLoader/HorizontalLoader";
import SkeletonLoader from "../../components/Loaders/SkeletonLoader/SkeletonLoader";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useLocation } from "react-router-dom";
import "./Search.css";
import SearchTile from "../../components/Tiles/SearchTile/SearchTile";
import Masonry from "react-masonry-css";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle.tsx";
import { useDispatch } from "react-redux";
import ReactMarkdown from 'react-markdown';
import {
  activateSearch,
  deactivateSearch,
} from "../../redux/actions/searchActions";
import WeblessFooter from "../../components/WeblessFooter/WeblessFooter";
import WeblessNavbar from "../../components/WeblessNavbar/WeblessNavbar";
import setFavicon from "../../utils/setFavicon";
import useEventCapture from "../../hooks/useEventCapture";
import { request } from "http";

interface ResponseItem {
  title: string;
  description: string;
  link: string;
  image: string;
  id: string;
}

interface MarketingMessage {
  link: string;
  message: string;
}

interface HighLights {
  text: string;
  url: string;
}

interface Data {
  responses: ResponseItem[];
  marketing_message: MarketingMessage;
  highlights: HighLights[];
}

interface ErrorType {
  message: string | null;
  status: number | null;
}

const Samsara: React.FC = () => {
  const [text, setText] = useState("");
  const [data, setData] = useState<Data>({
    responses: [],
    marketing_message: { link: "", message: "" },
    highlights: [],
  });
  const location = useLocation();
  const [tone, setTone] = useState<string>("");
  const [generationType, setGenerationType] = useState<string>("");
  const [summaryWordLimit, setSummaryWordLimit] = useState<string>("");
  const [bulletPointWordLimit, setBulletPointWordLimit] = useState<string>("");
  const [headlineWordLimit, setHeadlineWordLimit] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [summary, setSummary] = useState<string>("");
  const [numTiles, setNumTiles] = useState("");
  const [numSummaryTiles, setNumSummaryTiles] = useState("");
  const [userID, setUserID] = useState("");
  const [sessionID, setSessionID] = useState("");
  const [numHighlights, setNumHighlights] = useState("");
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const [useHistory, setUseHistory] = useState<boolean>(false);
  const [useMarkdown, setUseMarkdown] = useState<boolean>(false);
  const { fetchSummary } = useFetchSummary(setSummary, setLoadingSummary);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType>({
    message: null,
    status: null,
  });
  const width = useWindowWidth();
  const { token } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  useDocumentTitle("Search Page");
  setFavicon("/assets/images/webless_favicon.jpg");
  const [requestId, setRequestId] = useState<string>("");
  const { sendEvent } = useEventCapture();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tempSessionId = searchParams.get("sessionId") || uuidv4();
    let temp = sessionStorage.getItem("sessionId");
    const companyParam = searchParams.get("company");
    if (temp !== null) {
      setSessionID(temp);
    } else {
      sendEvent({
        event_type: "session_start",
        session_id: tempSessionId,
        token: token || "",
        page: "demo_page",
        company: companyParam || "",
      });
      sessionStorage.setItem("sessionId", tempSessionId);
    }
  }, []);

  const fetchData = async ({ text }: { text: string }) => {
    const requestID = uuidv4();
    setRequestId(requestID);
    try {
      (window as any).dataLayer?.push({ requestId: requestID });
      dispatch(deactivateSearch());
      setLoading(true);
      const queryParams: { [key: string]: string } = {
        query: text,
        requestId: requestID,
      };

      if (useHistory) {
        queryParams["use_history"] = useHistory.toString();
      }
      if (useMarkdown) {
        queryParams["markdown"] = useMarkdown.toString();
      }
      if (company !== "") {
        queryParams["company"] = company;
      }
      if (tone !== "") {
        queryParams["tone"] = tone;
      }
      if (generationType !== "") {
        queryParams["generation_type"] = generationType;
      }
      if (model !== "") {
        queryParams["model"] = model;
      }
      if (numTiles !== "") {
        queryParams["number_of_results"] = numTiles;
      }
      if (numSummaryTiles !== "") {
        queryParams["number_of_tiles"] = numSummaryTiles;
      }
      if (numHighlights !== "") {
        queryParams["num_highlights"] = numHighlights;
      }
      if (userID !== "") {
        queryParams["user_id"] = userID;
      }
      if (token) {
        queryParams["token"] = token;
      }
      if (sessionID) {
        queryParams["sessionId"] = sessionID;
      }
      if (summaryWordLimit){
        queryParams["summary_word_limit"] = summaryWordLimit;
      }
      if (bulletPointWordLimit){
        queryParams["bullet_point_word_limit"] = bulletPointWordLimit;
      }
      if (headlineWordLimit){
        queryParams["headline_word_limit"] = headlineWordLimit
      }

      sendEvent({
        event_type: "searchbar_click",
        session_id: sessionStorage.getItem("sessionId") || "",
        token: token || "",
        page: "search_page",
        company: company,
        request_id: requestID,
        query: text,
      });

      const res = await axios.post<Data>(
        `${process.env.REACT_APP_BACKEND_URL}/post_query`,
        queryParams
      );
      setData(res.data);
      fetchSummary(queryParams);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError({
          status: e.status || null,
          message: e.message || null,
        });
      } else {
        setError({ message: "An unexpected error occurred", status: 500 });
      }
      setLoading(false);
      dispatch(activateSearch());
      alert(error?.message);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuggestionClick = (text: string) => {
    setText(text);
    fetchData({ text });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const toneParameter = searchParams.get("tone");
    const generationTypeParameter = searchParams.get("generation_type");
    const modelParameter = searchParams.get("model");
    const companyParameter = searchParams.get("company");
    const numTilesParameter = searchParams.get("n");
    const userParameter = searchParams.get("user_id");
    const useHistoryParameter = searchParams.get("use_history");
    const markdownParameter = searchParams.get("markdown");
    const summaryWordLimitParameter = searchParams.get("summary_word_limit");
    const bulletPointWordLimitParameter = searchParams.get("bullet_point_word_limit");
    const headlineWordLimitParameter= searchParams.get("headline_word_limit");
    const numSummaryTilesParameter= searchParams.get("num_summary_tiles");
    if (toneParameter) {
      setTone(toneParameter);
      // Do something with the parameter value
    }
    if (generationTypeParameter) {
      setGenerationType(generationTypeParameter);
    }
    if (useHistoryParameter) {
      setUseHistory(true);
    }
    if (markdownParameter) {
      setUseMarkdown(true);
    }
    if (modelParameter) {
      setModel(modelParameter);
    }
    if (companyParameter) {
      setCompany(companyParameter);
    }
    if (numTilesParameter) {
      setNumTiles(numTilesParameter);
    }
    if (numSummaryTilesParameter) {
      setNumSummaryTiles(numSummaryTilesParameter);
    }
    if (userParameter) {
      setUserID(userParameter);
    }
    if (summaryWordLimitParameter) {
      setSummaryWordLimit(summaryWordLimitParameter);
    }
    if (bulletPointWordLimitParameter) {
      setBulletPointWordLimit(bulletPointWordLimitParameter);
    }
    if (headlineWordLimitParameter) {
      setHeadlineWordLimit(headlineWordLimitParameter);
    }
  }, [location.search]);

  const handleTileClick = (link: string, tileId: string) => {
    sendEvent({
      event_type: "tile_click",
      session_id: sessionStorage.getItem("sessionId") || "",
      token: token || "",
      page: "search_page",
      company: company,
      request_id: requestId,
      query: text,
      tileId: tileId,
    });

    window.open(link, "_blank");
  };

  return (
    <div className="search-page">
      <WeblessNavbar />
      <div className="top-container search">
        <SearchBar
          fetchData={fetchData}
          type={"default"}
          text={text}
          setText={setText}
        />
        <p className="result-desc default">
          {loadingSummary || loading ? (
            <div className="summary-skeleton">
              <div className="skeleton-line pulse"></div>
              <div className="skeleton-line pulse"></div>
              <div className="skeleton-line pulse"></div>
              <div className="skeleton-line-short pulse"></div>
            </div>
          ) : width < 765 ? (
            <ShowMoreText
              text={summary}
              maxLength={200}
              type={generationType}
            />
          ) : generationType === "bullet_point" ? (
            <ul className="bullet-point-summary">
              {summary.split("###").map((item, index) => {
                let temp = item.trim();
                if (temp.length > 0) {
                  return <li key={index}>{temp}</li>;
                }
                return null;
              })}
            </ul>
          ) : (
            <ReactMarkdown>{summary}</ReactMarkdown>
          )}
        </p>
      </div>
      {loading == false && data.highlights.length > 0 && (
        <div className="hightlight-container">
          <h2>Few highlights for you:</h2>
          {data.highlights.map((item, i) => {
            return (
              <p className="hightlight">
                {item.text} <a href={item.url}>Learn More</a>
              </p>
            );
          })}
        </div>
      )}
      {loading ? (
        <div className="marketing-message-container">
          <div className="skeleton-marketing-message pulse"></div>
        </div>
      ) : data.marketing_message.message !== "" && (
        <div
          className="marketing-message-container"
          onClick={() => {
            sendEvent({
              event_type: "click_cta",
              session_id: sessionID,
              token: token || "",
              page: "search_page",
              company: company,
              request_id: requestId,
            });
          }}
        >
          <a href={data.marketing_message.link} target="_blank">
            {data.marketing_message.message}
          </a>
        </div>
      )}
      <div className="search-cards-container">
        {loading ? (
          <div className="search-tiles-container">
            <Masonry
              breakpointCols={{ default: 3, 700: 2, 500: 1 }}
              className="my-masonry-grid"
              columnClassName="masonry-grid-column"
            >
              {[...Array(Number(numTiles) || 3)].map((_, index) => (
                <SkeletonLoader key={index} />
              ))}
            </Masonry>
          </div>
        ) : (
          <div className="search-tiles-container">
            <Masonry
              breakpointCols={{ default: 3, 700: 2, 500: 1 }}
              className="my-masonry-grid"
              columnClassName="masonry-grid-column"
            >
              {data.responses.map((item, i) => (
                <SearchTile
                  key={item.id}
                  title={item.title}
                  desc={item.description}
                  imgUrl={item.image}
                  link={item.link}
                  tileId={item.id}
                  handleTileClick={handleTileClick}
                />
              ))}
            </Masonry>
          </div>
        )}
      </div>
      <WeblessFooter />
    </div>
  );
};

export default Samsara;
